import { CepService, LeadService } from "../../servicos";
import { ActionService } from "../../servicos/action.service";
import { removeMask, setEventPageView, getTotal } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Cep, Configuration } from "../../modelos";
import { CheckoutDTO } from "../../dto";
import { useEffect, useState } from "react";
import { AnalyticsService } from "../../servicos/analyticsService";

export const Logic = (test: boolean = false) => {
    // const location = test ? null : useLocation();
    const location = useLocation();
    const [configuration, alterarConfiguration] = useState(location != null && location.state !== null && (location.state as any).configuration !== null && (location.state as any).configuration !== undefined ? (location.state as any).configuration : new Configuration());
    const dto: CheckoutDTO = location.state !== null && (location.state as any).dto !== null && (location.state as any).dto !== undefined ? (location.state as any).dto : new CheckoutDTO();

    if (location === null || location.state === null) {
        window.location.replace("/" + window.location.search);
    }

    // const navigate = test ? null : useNavigate();
    const navigate = useNavigate();

    const [loading, alterarLoading] = useState(false);
    const [franquiaId, alterarFranquiaId] = useState(dto.franquiaId ? dto.franquiaId : 0);
    const [canFocusNumero, alterarCanFocusNumero] = useState(false);

    const [showModalSergipe, setShowModalSergipe] = useState(false);
    const [isCepSergie, setIsCepSergipe] = useState(false);


    useEffect(() => {
        // document.head.innerHTML += configuration.meta!;
        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);

        if (dto.cep && dto.rua && dto.bairro && dto.cidade, dto.estado && dto.franquiaId != 0) {
            verificarCep();
            verificarRua();
            verificarBairro();
            verificarNumero();
        }
    }, [])

    const [canFocusCep, alterarCanFocusCep] = useState(false);
    const [cep, alterarCep] = useState(dto.cep ? dto.cep : "");
    const [cepValido, alterarCepValido] = useState(false);
    const [cepInvalido, alterarCepInvalido] = useState(false);
    const [cepMensagemErro, alterarCepMensagemErro] = useState("Campo obrigatório");
    const [respCep, alterarRespCep]: Cep | any = useState({});
    const [loadingCep, alterarLoadingCep] = useState(false);

    async function verificarCep() {
        var cepTratado = removeMask(cep);
        var message = cepMensagemErro;
        var invalid = cepInvalido;

        if (cepTratado.length <= 0) {
            invalid = true;
            alterarCepInvalido(true);
            alterarCepValido(false);
            alterarCepMensagemErro("Campo obrigatório");
            return;
        }
        else if (dto.cep && dto.rua && dto.bairro && dto.cidade, dto.estado && dto.franquiaId != 0) {
            alterarCepInvalido(false);
            alterarCepValido(true);
        }
        else {
            if ((respCep && !respCep.franquiaId) || cepTratado != cep) {
                alterarLoadingCep(true);
                invalid = await buscarCep(cepTratado, invalid);
                alterarLoadingCep(false);
            }
        }

        ActionService().sendInvalidField(invalid, message, cepTratado, "cep", configuration.ga_codigo!);
    }

    const buscarCep = async (cepTratado: string, invalid: boolean) => {
        alterarCep(cepTratado);
        var resp: Cep | any = await CepService().buscarCep(cepTratado, getForm());

        invalid = resp !== undefined && resp?.message !== undefined;

        alterarCepValido(resp !== undefined && resp?.message === undefined);
        alterarCepInvalido(resp !== undefined && resp?.message !== undefined);

        if (resp !== undefined && resp?.message === undefined) {
            alterarRespCep(resp);
        }

        if (resp !== undefined && resp?.message !== undefined) {
            alterarCepMensagemErro(resp.message);
        }       

        if(resp.uf === 'SE'){
            setShowModalSergipe(true);
        }

        if (window.dataLayer) {
            var indexcepInserted = window.dataLayer?.findIndex((x: { cep: string; }) => x.cep === cepTratado);
            if (indexcepInserted < 0) {
                let dataLayer = window.dataLayer || []
                dataLayer.push({
                    'event': 'cepInserted',
                    'cep': cepTratado,
                    'error': resp !== undefined && resp?.message === undefined ? "Sucesso" : resp.message,
                });
            }
        }

        if (resp !== undefined && resp?.message !== undefined)
            return resp.message;
    }

    const [rua, alterarRua] = useState(dto.rua ? dto.rua : "");
    const [ruaValido, alterarRuaValido] = useState(false);
    const [ruaInvalido, alterarRuaInvalido] = useState(false);
    const [ruaMensagemErro, alterarRuaMensagemErro] = useState("Campo obrigatório");
    function verificarRua() {
        alterarRuaInvalido(rua.length < 1);
        alterarRuaValido(rua.length >= 1);

        if (rua.length < 1)
            alterarRuaMensagemErro("Campo obrigatório");

        ActionService().sendInvalidField(rua.length < 1, ruaMensagemErro, rua, "rua", configuration.ga_codigo!);
    }

    const [bairro, alterarBairro] = useState(dto.bairro ? dto.bairro : "");
    const [bairroValido, alterarBairroValido] = useState(false);
    const [bairroInvalido, alterarBairroInvalido] = useState(false);
    const [bairroMensagemErro, alterarBairroMensagemErro] = useState("Campo obrigatório");
    function verificarBairro() {
        alterarBairroInvalido(bairro.length < 1);
        alterarBairroValido(bairro.length >= 1);

        if (bairro.length < 1)
            alterarBairroMensagemErro("Campo obrigatório");
    }

    const [numero, alterarNumero] = useState(dto.numero ? dto.numero : "");
    const [numeroValido, alterarNumeroValido] = useState(false);
    const [numeroInvalido, alterarNumeroInvalido] = useState(false);
    const [numeroMensagemErro, alterarNumeroMensagemErro] = useState("Campo obrigatório");
    function verificarNumero() {
        var numeroTratado = removeMask(numero);

        alterarNumero(numeroTratado);
        alterarNumeroInvalido(numeroTratado.length < 1);
        alterarNumeroValido(numeroTratado.length >= 1);

        if (numeroTratado.length <= 0)
            alterarNumeroMensagemErro("Campo obrigatório");

        ActionService().sendInvalidField(numeroTratado.length < 1, numeroMensagemErro, numeroTratado, "numero", configuration.ga_codigo!);
    }

    const [complemento, alterarComplemento] = useState(dto.complemento ? dto.complemento : "");
    const [cidade, alterarCidade] = useState(dto.cidade ? dto.cidade : "");
    const [estado, alterarEstado] = useState(dto.estado ? dto.estado : "");
    const [franquiaNome, alterarfranquiaNome] = useState(dto.franquiaNome ? dto.franquiaNome : "");

    const [termos, alterarTermos] = useState(false);
    const [termosSmsEmail, alterarTermosSmsEmail] = useState(false);
    const [termosInvalido, alterarTermosInvalido] = useState(false);
    const [termosMensagemErro, alterarTermosMensagemErro] = useState("Campo obrigatório");

    function verificarTermos() {
        alterarTermosInvalido(termos === false);
        if (!termos)
            alterarTermosMensagemErro("Campo obrigatório");

        ActionService().sendInvalidField(termos === false, termosMensagemErro, termos.toString(), "termos", configuration.ga_codigo!);
    }

    function getForm() {
        return {

            //CEP
            cep,
            franquiaId,
            franquiaNome,
            rua,
            bairro,
            cidade,
            estado,
            numero,
            complemento,

            //Dados pessoais
            nome: dto.nome,
            cpf: dto.cpf,
            email: dto.email,
            telefone: dto.telefone,
            data_nascimento: dto.data_nascimento,
            maior_dezoito: dto.maior_dezoito,
            termosSmsEmail: dto.termosSmsEmail,

            //Outros
            voucher: dto.voucher,
            linkAfiliado: configuration.linkAfiliado,
            id_promotor_lead: configuration.id_promotor_lead ? configuration.id_promotor_lead : '',
            campanha_publicitaria: getCampanhaPublicitaria().value,
            ga_codigo: configuration.ga_codigo,


            utm_source: dto.utm_source,
            utm_campaign: dto.utm_campaign,
            utm_content: dto.utm_content,
            utm_medium: dto.utm_medium,
            utm_term: dto.utm_term,
            params: dto.params,

            termos: termos
        } as CheckoutDTO;
    }

    async function backPage() {
        navigate("/dados-pessoais" + window.location.search, { state: { configuration, dto: getForm() } });
    }

    async function handleSubmit(event: any) {
        event?.preventDefault();
        event?.stopPropagation();

        verificarCep();
        verificarRua();
        verificarBairro();
        verificarNumero();

        if (cepValido && ruaValido && bairroValido && numeroValido) {

            alterarLoading(true);
            var dto = getForm();
            await LeadService().sendLead(dto);
            alterarLoading(false);

            var indexShippingInfo = window.dataLayer?.findIndex((x: { event: string; }) => x.event === "add_shipping_info");
            if (indexShippingInfo < 0) {
                let dataLayer = window.dataLayer || []
                dataLayer.push({
                    'formCep': cep,
                    ecommerce: {
                      items: [
                        {
                            item_name: 'Filiação Cartão de Todos',
                            item_id: 1,
                            price: configuration.prices.mensalidade + configuration.prices.adesao,
                            item_brand: 'CDT Site',
                            quantity: 1
                        }   
                      ]
                    },
                    'event': 'add_shipping_info'
                  });
            }

            AnalyticsService().SendAnalytics(
                {
                    event: "CepInserted",
                    channel: "checkout-adquira",
                    parameters: `{cep: ${dto.cep ? dto.cep : null}, status: ${dto.cep ? 'sucesso' : 'erro'}}`,
                    source: dto.utm_source ? dto.utm_source : null,
                    campaign: dto.utm_campaign ? dto.utm_campaign : null,
                    url: window.location.origin,
                    lead: {
                        name: dto.nome,
                        email: dto.email,
                    }
                }
            );

            AnalyticsService().SendAnalytics(
                {
                    "event": "LeadAddress",
                    channel: "checkout-adquira",
                    parameters: `{id: ${dto.franquiaId ? dto.franquiaId : null}, franchise: ${dto.franquiaNome}}`,
                    source: dto.utm_source ? dto.utm_source : null,
                    campaign: dto.utm_campaign ? dto.utm_campaign : null,
                    url: window.location.origin,
                    lead: {
                        name: dto.nome,
                        email: dto.email,
                    }
                }
            );

            navigate("/metodo-de-pagamento" + window.location.search, { state: { configuration, dto: getForm() } });
        }

    }

    useEffect(() => {
        if (dto.termosSmsEmail) {
            const handleBeforeUnload = (e) => {
                e.preventDefault();
                var form = getForm();
                form.fechou_checkout = true;
                LeadService().sendLead(form);

                AnalyticsService().SendAnalytics(
                    {
                        event: "LeaveCheckout",
                        channel: "checkout-adquira",
                        parameters: 'Fechou checkout - Endereço',
                        source: dto.utm_source ? dto.utm_source : null,
                        campaign: dto.utm_campaign ? dto.utm_campaign : null,
                        url: window.location.origin,
                        lead: {
                            name: dto.nome,
                            email: dto.email,
                        }
                    }
                );

                return (e.returnValue = "Tem certeza que deseja sair?");
            };

            window.addEventListener("beforeunload", handleBeforeUnload);

            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        }
    }, [dto.termosSmsEmail]);

    useEffect(() => {
        //Kwanko Product
        if (getParam("utm_source=").value === "kwanko") {
            const script = document.createElement('script');
            script.src = 'https://img.metaffiliation.com/u/24/p77473.js';
            document.head.appendChild(script);

            window.ptag_params = {
                zone: 'product',
                productId: '1',
                categoryId: ''
            };
        }
    }, []);

    return {
        configuration, backPage, handleSubmit, loading,

        cep, alterarCep, verificarCep, cepValido, cepInvalido, cepMensagemErro, canFocusCep, alterarCanFocusCep, loadingCep,
        rua, alterarRua, verificarRua, ruaValido, ruaInvalido, ruaMensagemErro,
        bairro, alterarBairro, verificarBairro, bairroValido, bairroInvalido, bairroMensagemErro,
        numero, alterarNumero, verificarNumero, numeroValido, numeroInvalido, numeroMensagemErro,
        complemento, alterarComplemento,
        cidade, alterarCidade, estado, alterarEstado,
        getForm,
        showModalSergipe, setShowModalSergipe,
        termos, alterarTermos, verificarTermos, termosInvalido, termosMensagemErro,
    };

}