import { Button, Row, Col, Spinner, Form } from "react-bootstrap";
import { doubleToString, focusOn } from '../../utils/utils';
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Configuration } from '../../modelos';
import InputMask from "react-input-mask";
import { Logic } from './logic';
import { ModalTermosComponente } from '../modal_termos.componente';
import InputComponent from '../input.component';
import CheckboxComponent from '../checkbox.component';
import { Loading } from '../loading.component';

import { ModalLoading3DS } from "../modal_loading_3ds";

import { ReactComponent as IconItau } from '../../assets/icons/banco-itau.svg';
import { ReactComponent as IconBradesco } from '../../assets/icons/banco-bradesco.svg';
import { ReactComponent as IconInter } from '../../assets/icons/banco-inter.svg';
import { ReactComponent as IconNubank } from '../../assets/icons/banco-nubank.svg';
import { ReactComponent as IconC6 } from '../../assets/icons/banco-c6.svg';
import { ReactComponent as IconBBrasil } from '../../assets/icons/banco-brasil.svg';
import { ReactComponent as IconMastercard } from '../../assets/icons/mastercard.svg';
import { ReactComponent as IconVisa } from '../../assets/icons/visa.svg'
import { ReactComponent as IconElo } from '../../assets/icons/elo.svg'

export const MetodoPagamentoComponente = (props: { configuration: Configuration, handleSubmit: any, handleSubmit3DS: any, formularioPagamento?: any, callRecaptcha?: any, 
    showError?: any, alterarShowError?: any, loading?: any, iniCreditoAberto?: boolean, }) => {

    const form = useRef<HTMLFormElement>(null);

    const componentLogic = Logic({
        alterarShowError: props.alterarShowError, configuration: props.configuration, form: form, formularioPagamento: props.formularioPagamento,
        handleSubmit3DS: props.handleSubmit3DS, handleSubmit: props.handleSubmit, loading3DS: props.loading
    });

    useLayoutEffect(() => {
        if (props.iniCreditoAberto)
            componentLogic.alterarMostrarCredito(props.iniCreditoAberto);
    }, [componentLogic, props.iniCreditoAberto]);

    const [showMeDebitCard, setShowMeDebitCard] = useState(false);

    return (
        <>
            <div id="threeDSFingerPrint_result"></div>
            <div id="threeDSFingerPrint_result2"></div>


          { componentLogic.method === 'debito' && 
            <>
            <div> <p className="pt-4">Bancos disponíveis para pagamento</p></div>
            <div className="row justify-content-start">
                <div className="col-auto"><IconItau /></div>
                <div className="col-auto"><IconBradesco /></div>
                <div className="col-auto"><IconInter /></div>
                <div className="col-auto"><IconNubank /></div>
                <div className="col-auto"><IconC6 /></div>
                <div className="col-auto"><IconBBrasil /></div>
            </div>

            <div className="detail-outrosbancos mt-4">
                {!showMeDebitCard ? 
                    <>
                    <p className="mb-1">Consulte as bandeiras e bancos disponíveis {' '}
                     <button type="button" className="btn-readmore" onClick={() => {setShowMeDebitCard(true)}}>Saiba mais</button>
                    </p>
                    </>
                    :
                    <></>
                }

                {showMeDebitCard ? 
                <div className="list-detail-outrosbancos mb-4">
                    <div className="row align-items-center">
                        <IconMastercard className="col-auto" />
                        <div className="col">
                            <h6>Mastercard</h6>
                            {/* <p>Banco BMG, Banco de Brasilia, Banco do Brasil, Banco Inter, Banco Original, Caixa, C6 Bank, Itaú, Nubank, Cartão online Santander e Sicred</p> */}
                            <p>Banco do Brasil, Banco Inter, C6 Bank, Itaú, Nubank, Cartão online Santander</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row align-items-center">
                        <IconVisa className="col-auto" />
                        <div className="ms-2 col">
                            <h6>Visa</h6>
                            {/* <p>Banco do Brasil, Bradesco, Caixa, Itaú, Cartão online Santander e Sicred</p> */}
                            <p>Banco do Brasil, Bradesco, Caixa, Itaú, Cartão online Santander</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row align-items-center">
                        <IconElo className="col-auto" />
                        <div className="ms-2 col">
                            <h6>Elo</h6>
                            {/* <p>Banco do Brasil, Bradesco e Caixa</p> */}
                            <p>Banco do Brasil, Bradesco</p>
                        </div>
                    </div>
                    <hr />
                    <button type="button" className="btn-readmore" onClick={() => {setShowMeDebitCard(false)}}>Ok entendi</button>
                </div>
                :
                <></>
                }
            </div>
        </>
          }
           
            <div className="pt-2">
                <Form ref={form} noValidate onSubmit={componentLogic.method === 'credito' ? componentLogic.handleSubmit : componentLogic.handleSubmit3DS}>
                    <div className="row">
                        <div className="col">
                            <InputComponent
                                id="numeroCartao"
                                title="NÚMERO DO CARTÃO"
                                as={InputMask}
                                mask="9999 9999 9999 9999"
                                type="text"
                                placeholder="0000 0000 0000 0000"
                                value={componentLogic.numeroCartao}
                                onChange={(event) => componentLogic.alterarNumeroCartao(event.target.value)}
                                onBlur={componentLogic.verificarNumeroCartao}
                                isValid={componentLogic.numeroCartaoValido}
                                isInvalid={componentLogic.numeroCartaoInvalido}
                                disabled={false}
                                enterKeyHint="next"
                                onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "nomeTitular")}
                                errorMessage={componentLogic.numeroCartaoMensagemErro}
                                required />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <InputComponent
                                id="nomeTitular"
                                title="NOME DO CARTÃO"
                                as={InputMask}
                                type="text"
                                placeholder="ex. Maria Lucia Almeida "
                                value={componentLogic.nome}
                                onChange={(event) => componentLogic.alterarNome(event.target.value)}
                                onBlur={componentLogic.verificarNome}
                                isValid={componentLogic.nomeValido}
                                isInvalid={componentLogic.nomeInvalido}
                                disabled={false}
                                enterKeyHint="next"
                                onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "validadeCartao")}
                                errorMessage={componentLogic.nomeMensagemErro}
                                required />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-6">
                            <InputComponent
                                id="validadeCartao"
                                title="DATA DE VENCIMENTO"
                                as={InputMask}
                                mask="99/99"
                                type="text"
                                placeholder="10/10"
                                value={componentLogic.validade}
                                onChange={(event) => componentLogic.alterarValidade(event.target.value)}
                                onBlur={componentLogic.verificarValidade}
                                isValid={componentLogic.validadeValido}
                                isInvalid={componentLogic.validadeInvalido}
                                enterKeyHint="next"
                                onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "cvvCartao")}
                                errorMessage={componentLogic.validadeMensagemErro}
                                required />
                        </div>
                        <div className="col-6">
                            <InputComponent
                                id="cvvCartao"
                                title="CVV"
                                as={InputMask}
                                mask="999"
                                type="text"
                                placeholder="123"
                                value={componentLogic.cvv}
                                onChange={(event) => componentLogic.alterarCvv(event.target.value)}
                                onBlur={componentLogic.verificarCvv}
                                isValid={componentLogic.cvvValido}
                                isInvalid={componentLogic.cvvInvalido}
                                disabled={false}
                                enterKeyHint="next"
                                onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "cpf")}
                                errorMessage={componentLogic.cvvMensagemErro}
                                required />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <InputComponent
                                id="cpfCartao"
                                title="CPF"
                                as={InputMask}
                                mask="999.999.999-99"
                                type="text"
                                placeholder="000.000.000-00"
                                value={componentLogic.cpf}
                                onChange={(event) => componentLogic.alterarCpf(event.target.value)}
                                onBlur={componentLogic.verificarCpf}
                                isValid={componentLogic.cpfValido}
                                isInvalid={componentLogic.cpfInvalido}
                                disabled={false}
                                enterKeyHint="next"
                                onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "cpf")}
                                errorMessage={componentLogic.cpfMensagemErro}
                                required />
                        </div>
                    </div>

                    {props.showError ?
                        <p className="mt-3 px-3 text-center">
                            <strong className="text-danger">
                                Ops, ocorreu um erro na transação, tente mais tarde!
                            </strong>
                        </p> : null}

                    <div>
                        <CheckboxComponent
                            title="Termos"
                            value={componentLogic.termos}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => componentLogic.alterarTermos(value => value = event.target.checked)}
                            onBlur={componentLogic.verificarTermos}
                            isInvalid={componentLogic.termosInvalido}
                            errorMessage={componentLogic.termosMensagemErro}
                            required
                            customLabel={<>
                                Li e aceito os <button type="button"
                                    onClick={() => componentLogic.setModalShow(true)}
                                    className="underline text-link-terms"><strong>Termos e Condições</strong></button>
                            </>}
                        />
                    </div>

                   
                    <div className="row">
                        <div className="col">
                            <div className="button-start d-xl-grid d-grid gap-2 mb-4 mb-xl-3 pt-2">
                                {props.loading || componentLogic.loading3DS ?
                                    <div className="submit-loader mx-auto">Loading...</div>
                                    :
                                    <Button variant="secondary" size="lg" type="submit" disabled={props.loading || componentLogic.loading3DS}>
                                        SOLICITAR CARTÃO
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
                <p className="mt-3">
                    <small className={`text-muted text-center ${props.configuration.prices!.mensalidade! > 0 ? '' : 'shimmer'}`}>
                        * Pagamento mensal de R$ {doubleToString(props.configuration.prices!.mensalidade!)}.<br />
                        ** O pagamento inicial será de R$ {doubleToString(props.configuration.prices!.mensalidade! + props.configuration.prices!.adesao!)}, pois inclui a taxa de adesão no valor de R$ {doubleToString(props.configuration.prices!.adesao!)} mais a primeira mensalidade no valor de R$ {doubleToString(props.configuration.prices!.mensalidade!)}.<br />
                        ***Valor sujeito a alteração devido ao uso de voucher.
                    </small>
                </p>
                {window.innerWidth < 1199.98 ?
                    <Row className="mt-4 mb-3 pt-2">
                        <Col sm={12}>
                            <div className="text-center" dangerouslySetInnerHTML={{ __html: props.configuration.helpTextOthers! }}></div>
                        </Col>
                    </Row> : <></>}
            </div >
            <ModalTermosComponente
                show={componentLogic.modalShow}
                onHide={() => componentLogic.setModalShow(false)}
            />

                <ModalLoading3DS
                show={componentLogic.modalLoading3DS}
                percentLoading={componentLogic.percentLoading}
                onHide={() => componentLogic.setModalLoading3DS(false)}
            />
        </>
    )
}