import { useLocation, useNavigate } from "react-router-dom";
import { Configuration } from "../../modelos";
import { useEffect, useState } from "react";
import { setEventPageView, dateComparePeriod } from "../../utils/utils";
import { AnalyticsService } from "../../servicos/analyticsService";
import { CheckoutDTO } from "../../dto";

export const Logic = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [modalSazonalShow, setModalSazonalShow] = useState(false);

    const configuration: Configuration = location.state !== null && (location.state as any).configuration !== null && (location.state as any).configuration !== undefined ? (location.state as any).configuration : new Configuration();
    const matricula: string = location.state !== null && (location.state as any).matricula !== null && (location.state as any).matricula !== undefined ? (location.state as any).matricula : "";
    const dto: CheckoutDTO = location.state !== null && (location.state as any).dto !== null && (location.state as any).dto !== undefined ? (location.state as any).dto : new CheckoutDTO();

    if (location.state === null) {
        window.location.replace("/" + window.location.search);
    }

    const modalSazonalidade = () => {
        setModalSazonalShow(dateComparePeriod("08/01/2022", "08/05/2022").insidePeriod);
    }

    function init() {
        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);
        modalSazonalidade();
    }

    const handleSave = () => {
        navigate("/dependentes-sucesso", { state: { configuration } });
    }

    useEffect(() => {
        AnalyticsService().SendAnalytics(
            {
              event: "PurchaseError",
              channel: "checkout-adquira",
              parameters: 'Erro ao processar o pagamento, verifique os dados do cartão',
              source: dto.utm_source ? dto.utm_source : null,
              campaign: dto.utm_campaign ? dto.utm_campaign : null,
              url: window.location.origin,
              lead: {
                name: dto.nome,
                email: dto.email,
              }
            }
          );
    },
    [])

    return { init, configuration, modalSazonalShow, setModalSazonalShow, modalShow, setModalShow, handleSave, matricula };
}