import { CpfService, EmailService, FiliacaoService, LeadService, MarketingConversionService } from "../../servicos";
import { ActionService } from "../../servicos/action.service";
import { getCardType, getCookie, removeMask, validarCPF, varlidarEmail, setEventPageView, createCookie, getTotal, hash, getFbPixelDebug } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Configuration, Cpf } from "../../modelos";
import { CheckoutDTO, PagamentoDTO, StepDTO } from "../../dto";
import { useEffect, useState } from "react";
import { AnalyticsService } from "../../servicos/analyticsService";
import { KwankoService } from "../../servicos/kwanko.service";

export const Logic = (test: boolean = false) => {
    // const location = test ? null : useLocation();
    const location = useLocation();
    const [configuration, alterarConfiguration] = useState(location != null && location.state !== null && (location.state as any).configuration !== null && (location.state as any).configuration !== undefined ? (location.state as any).configuration : new Configuration());
    const dto: CheckoutDTO = location.state !== null && (location.state as any).dto !== null && (location.state as any).dto !== undefined ? (location.state as any).dto : new CheckoutDTO();

    if (location === null || location.state === null) {
        window.location.replace("/" + window.location.search);
    }

    const navigate = test ? null : useNavigate();
    const [habilitadoPessoa, alterarHabilitadoPessoa] = useState(true);
    const [habilitadoEndereco, alterarHabilitadoEndereco] = useState(true);
    const [modalShow, setModalShow] = useState(false);

    const [franquiaId, alterarFranquiaId] = useState(0);
    const [loading, alterarLoading] = useState(false);
    const [captcha, alterarcaptcha] = useState("");
    const [processoPagamento, alterarProcessoPagamento] = useState(false);

    function init() {
        // document.head.innerHTML += configuration.meta!;
        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);

        if (window.adyen == null) {
            const script = document.createElement("script");
            script.src = `${window.location.origin}/adyen.encrypt.min.js`;
            script.async = true;
            document.body.appendChild(script);
        }
    }

    const [nome, alterarNome] = useState("");
    const [nomeValido, alterarNomeValido] = useState(false);
    const [nomeInvalido, alterarNomeInvalido] = useState(false);
    const [nomeMensagemErro, alterarNomeMensagemErro] = useState("Campo obrigatório");
    function verificarNome() {
        var hasNumber = /\d/;
        var message = nomeMensagemErro;
        var invalid = nome.length < 3 || hasNumber.test(nome);

        alterarNomeInvalido(invalid);
        alterarNomeValido(nome.length >= 3 && !hasNumber.test(nome));

        if (nome.length <= 0)
            message = "Campo obrigatório";
        else if (hasNumber.test(nome)) {
            message = "Nome inválido";
            alterarNomeInvalido(true);
            alterarNomeValido(false);
        }
        else
            message = "Digite seu nome completo";


        alterarNomeMensagemErro(message);

        ActionService().sendInvalidField(invalid, message, nome, "nome", configuration.ga_codigo!);
    }

    const [cpf, alterarCpf] = useState("");
    const [cpfValido, alterarCpfValido] = useState(false);
    const [cpfInvalido, alterarCpfInvalido] = useState(false);
    const [cpfMensagemErro, alterarCpfMensagemErro] = useState("Campo obrigatório");
    function verificarCpf() {
        var cpfTratado = removeMask(cpf);
        var eValido: boolean = validarCPF(cpfTratado);
        var message = cpfMensagemErro;

        alterarCpf(cpfTratado);
        alterarCpfInvalido(!eValido);
        alterarCpfValido(eValido);

        if (cpfTratado.length <= 0)
            alterarCpfMensagemErro("Campo obrigatório");
        else {
            if (eValido && cpf !== cpfTratado) {
                buscarCpf(cpfTratado, eValido);
            }
            else {
                message = 'CPF inválido';
                alterarCpfMensagemErro(message);
            }
        }

        ActionService().sendInvalidField(!eValido, message, cpfTratado, "cpf", configuration.ga_codigo!);
    }

    const buscarCpf = async (cpfTratado: string, eValido: boolean) => {
        var resp: Cpf | any = await CpfService().validarCpf(cpfTratado);

        if (resp !== undefined && resp.cadastrado) {
            eValido = false;
            alterarCpfInvalido(true);
            alterarCpfValido(false);
            alterarCpfMensagemErro("CPF já cadastrado");
        }
        else {
            eValido = true;
        }
    }

    const [termos, alterarTermos] = useState(false);
    const [termosSmsEmail, alterarTermosSmsEmail] = useState(false);
    const [termosInvalido, alterarTermosInvalido] = useState(false);
    const [termosMensagemErro, alterarTermosMensagemErro] = useState("Campo obrigatório");
    function verificarTermos() {
        alterarTermosInvalido(termos === false);
        if (!termos)
            alterarTermosMensagemErro("Campo obrigatório");

        ActionService().sendInvalidField(termos === false, termosMensagemErro, termos.toString(), "termos", configuration.ga_codigo!);
    }

    const enderecoAccordion = document.getElementById("enderecoAccordion")?.getElementsByClassName("accordion-button")[0];
    const pagamentoAccordion = document.getElementById("pagamentoAccordion")?.getElementsByClassName("accordion-button")[0];
    enderecoAccordion?.setAttribute("disabled", "disabled");
    pagamentoAccordion?.setAttribute("disabled", "disabled");

    function getForm() {
        return {

            //CEP
            cep: dto.cep,
            franquiaId: dto.franquiaId,
            franquiaNome: dto.franquiaNome,
            rua: dto.rua,
            bairro: dto.bairro,
            cidade: dto.cidade,
            estado: dto.estado,
            numero: dto.numero,
            complemento: dto.complemento,

            //Usuário
            nome: dto.nome,
            cpf: dto.cpf,
            email: dto.email,
            telefone: dto.telefone,
            data_nascimento: dto.data_nascimento,
            maior_dezoito: dto.maior_dezoito,


            forma_pagamento_selec: dto.forma_pagamento_selec,


            //Outros
            termosSmsEmail: dto.termosSmsEmail,
            voucher: dto.voucher,
            linkAfiliado: configuration.linkAfiliado,
            id_promotor_lead: configuration.id_promotor_lead ? configuration.id_promotor_lead : '',
            campanha_publicitaria: getCampanhaPublicitaria().value,
            ga_codigo: configuration.ga_codigo,

            utm_source: dto.utm_source,
            utm_campaign: dto.utm_campaign,
            utm_content: dto.utm_content,
            utm_medium: dto.utm_medium,
            utm_term: dto.utm_term,
            params: dto.params,
        } as CheckoutDTO;
    }

    async function sendOptIn(value: boolean) {
        var form = getForm();
        form.termosSmsEmail = value;
        await LeadService().sendLead(form);
    }

    async function backPage() {
        if (navigate != null) navigate("/metodo-de-pagamento" + window.location.search, { state: { configuration, dto: getForm() } });
    }

    async function handleSubmit(event: any, formCartao: any, captchaToken: any = '', form?: any) {        
        var success = false;
        event?.preventDefault();
        event?.stopPropagation();

        if ((formCartao !== undefined) || form) {
            alterarLoading(true);

            var dto = form ? form : getForm() as CheckoutDTO;
            dto.linkAfiliado = configuration.linkAfiliado;
          
            if (formCartao.bankName === "outrosbancos") {   
                dto.pagamento = {
                    numeroCartao: formCartao.number,
                    nomeCartao: formCartao.holderName,
                    codigoSeguranca: formCartao.cvc,
                    ano: Number.parseInt(`${new Date().getFullYear().toString().substring(0, 2)}${formCartao.expiryYear}`),
                    mes: Number.parseInt(formCartao.expiryMonth),
                    criptografiaAdyen: formCartao.cryptData,
                    bandeira: getCardType(formCartao.number),
                    total: getTotal(configuration.prices!.mensalidade!, configuration.prices!.adesao!),
                    bankName: formCartao.bankName,
                    method: formCartao.method,

                    referencia: formCartao.referencia,
                    threeDSPayment: formCartao.threeDSPayment,
                    cardid: formCartao.cardid,
                    threeDSTiD:formCartao.threeDSTiD,
                    threeDSFingerPrint: formCartao.threeDSFingerPrint,

                    adesao : formCartao.adesao,
                    mensalidade : formCartao.mensalidade,
                }as PagamentoDTO ;
            }else{
                dto.pagamento = {
                    numeroCartao: formCartao.number,
                    nomeCartao: formCartao.holderName,
                    codigoSeguranca: formCartao.cvc,
                    ano: Number.parseInt(`${new Date().getFullYear().toString().substring(0, 2)}${formCartao.expiryYear}`),
                    mes: Number.parseInt(formCartao.expiryMonth),
                    criptografiaAdyen: formCartao.cryptData,
                    bandeira: getCardType(formCartao.number),
                    total: getTotal(configuration.prices!.mensalidade!, configuration.prices!.adesao!),
                    bankName: formCartao.bankName,
                    method: formCartao.method,
                };
            }           

            if(captchaToken === '') {
                captchaToken = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'checkout'});;
            }
            
            if (captchaToken) {
                alterarProcessoPagamento(true);               

                if(formCartao.bankName === "outrosbancos" &&  formCartao.cardid[0] !== '' && formCartao.cardid[1]  !== '' ){                    
                    var resp = await FiliacaoService().sendFiliacao(dto, captchaToken) as any;
                }

                if(formCartao.method === "credito"){
                    var resp = await FiliacaoService().sendFiliacao(dto, captchaToken) as any;
                }
                
                if (resp !== undefined && resp.success) {
                    console.log('q3');

                    success = true;

                    var dataLayer = window.dataLayer || []
                    dataLayer.push({
                      'orderDocument': dto.cpf,
                      'orderPhone': dto.telefone,
                      'orderEmail': dto.email,
                      ecommerce: {
                        transaction_id: resp.data.tid,     
                        affiliation: 'CDT Checkout',
                        value: getTotal(configuration.prices!.mensalidade!, configuration.prices!.adesao!),
                        tax: '0',
                        shipping: '0',
                        currency: 'BRL',
                        payment_type: dto.pagamento?.method ? dto.pagamento?.method : '',
                        coupon:  dto.voucher,
                        items: [
                          {
                            item_name: 'Filiação Cartão de Todos',
                            item_id: 1,
                            price: configuration.prices.mensalidade + configuration.prices.adesao,
                            item_brand: 'CDT Checkout',
                            quantity: 1,
                            coupon:  dto.voucher,   
                          }
                        ]
                      },
                      event: 'purchase',
                      idpv: getIdpv().has ? getIdpv().value : ""
                    });


                    var successDTO = new StepDTO();

                    successDTO.user = configuration.ga_codigo;
                    successDTO.data = {
                        form: getForm()
                    }
                    ActionService().sendSuccess(successDTO);

                    if (getParam("utm_source=").value === "kwanko") {
                        KwankoService().KwankoTracking({
                            amount: getTotal(configuration.prices!.mensalidade!, configuration.prices!.adesao!),
                            eventID: resp.data.tid,
                            payname: formCartao.method,
                            currency: 'BRL',
                            voucherCode: dto.voucher ? dto.voucher : ''
                        });
                    }


                    AnalyticsService().SendAnalytics(
                        {
                            "event": "Purchase",
                            channel: "checkout-adquira",
                            parameters: JSON.stringify({ 
                                payment_method: formCartao.method === 'credito' ? 'credit-card' : formCartao.method === 'debito' ? 'debit' : 'pix',
                                 message:  resp.message ? resp.message : null
                                }),
                            source: dto.utm_source ? dto.utm_source : null,
                            campaign: dto.utm_campaign ? dto.utm_campaign : null,
                            url: window.location.origin,
                            lead: {
                                name: dto.nome,
                                email: dto.email,
                                purchased: success,
                                registrationCode: resp.data.matricula,
                                ctnId: resp.data.idFiliado
                            }
                        }
                    );

                    if (getParam("utm_source=").value === "kwanko") {
                        //Kwanko Transaction
                        const script = document.createElement('script');
                        script.src = 'https://img.metaffiliation.com/u/24/p77473.js';
                        document.head.appendChild(script);

                        window.ptag_params = {
                            zone: 'transaction',
                            products: [
                                {
                                    id: "1",
                                    price: getTotal(configuration.prices!.mensalidade!, configuration.prices!.adesao!),
                                    quantity: '1'
                                },
                            ],
                            transactionId: resp.data.matricula,
                            currency: 'BRL'
                        };
                    }


                    var count = window.localStorage.getItem("count");
                    if (count) {
                        var localCount = Number.parseInt(count);
                        localCount++;

                        window.localStorage.setItem("count", localCount.toString());
                    }

                    if (navigate != null) navigate("/sucesso" + window.location.search, { state: { configuration, dto: getForm(), filiado: resp.data } });

                }
                else {
                    success = false;
                    dto.pagamento = undefined;

                    let dataLayer = window.dataLayer || []
                    dataLayer.push({
                        event: 'payment_error',
                        orderMessage: 'Erro ao processar o pagamento, verifique os dados do cartão',
                        orderStatusCode: '404',
                        orderDescription: 'not found',
                        idpv: getIdpv().has ? getIdpv().value : ""
                    });

                    var failedDTO = new StepDTO();

                    failedDTO.user = configuration.ga_codigo;
                    failedDTO.data = {
                        form: getForm()
                    }
                    ActionService().sendSuccess(failedDTO);

                    /**
                     * Quiz Cartão de todos
                     * - Faz o redirecionamento para o quiz de formas de pagamento caso o cartão do cliente seja recusado por qualquer motivo.
                     */
                    // const formData = getForm();
                    // var userData = window.btoa(JSON.stringify({name: formData.nome, phone: formData.telefone ,email: formData.email}));
                    // if(userData)
                    //     return window.location.href = `https://quiz.${process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'dev.' : ''}cartaodetodos.com.br/meio-pagamento/?app_data=${userData}`;

                    if (navigate != null) navigate("/analise" + window.location.search, { state: { configuration, dto, total: getTotal(configuration.prices!.mensalidade!, configuration.prices!.adesao!) } });
                }
            }

            else {
                var dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'erroPagamento',
                    'statusCode': '402',
                    'description': 'reCaptcha refused',
                });
            }

            alterarLoading(false);
        }

        return success;
    }

    useEffect(() => {
        if (dto.termosSmsEmail && !processoPagamento) {
            const handleBeforeUnload = (e) => {
                e.preventDefault();
                var form = getForm();
                form.fechou_checkout = true;
                LeadService().sendLead(form);

                AnalyticsService().SendAnalytics(
                    {
                        event: "LeaveCheckout",
                        channel: "checkout-adquira",
                        parameters: 'Fechou checkout - Pagamento',
                        source: dto.utm_source ? dto.utm_source : null,
                        campaign: dto.utm_campaign ? dto.utm_campaign : null,
                        url: window.location.origin,
                        lead: {
                            name: dto.nome,
                            email: dto.email,
                        }
                    }
                );

                return (e.returnValue = "Tem certeza que deseja sair?");
            };

            window.addEventListener("beforeunload", handleBeforeUnload);

            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        }
    }, [dto.termosSmsEmail, processoPagamento]);


    useEffect(() => {
        if (dto.voucher) {
            AnalyticsService().SendAnalytics(
                {
                    event: "VoucherInserted",
                    channel: "checkout-adquira",
                    parameters: dto.voucher,
                    source: dto.utm_source ? dto.utm_source : null,
                    campaign: dto.utm_campaign ? dto.utm_campaign : null,
                    url: window.location.origin,
                    lead: {
                        name: dto.nome,
                        email: dto.email,
                    }
                }
            );
        }
    }, [dto.voucher])

    useEffect(() => {
        if (getParam("utm_source=").value === "kwanko") {
            //Kwanko Basket
            const script = document.createElement('script');
            script.src = 'https://img.metaffiliation.com/u/24/p77473.js';
            document.head.appendChild(script);

            window.ptag_params = {
                zone: 'basket',
                products: [{ id: "1", price: getTotal(configuration.prices!.mensalidade!, configuration.prices!.adesao!), quantity: '1' },],
                currency: 'BRL'
            };
        }
    }, []);


    return {
        configuration, init, handleSubmit, backPage,

        nome, alterarNome, verificarNome, nomeValido, nomeInvalido, nomeMensagemErro,
        cpf, alterarCpf, verificarCpf, cpfValido, cpfInvalido, cpfMensagemErro,
        termos, alterarTermos, verificarTermos, termosInvalido, termosMensagemErro, termosSmsEmail, alterarTermosSmsEmail,

        habilitadoPessoa, habilitadoEndereco, getForm, sendOptIn,
        modalShow, setModalShow, loading,
        captcha, alterarcaptcha
    };

}