import { CheckoutDTO, FiliacaoResponseDTO } from "../dto";
import axiosInstance from "../utils/axiosInstance";

export const FiliacaoService = () => {
    const sendFiliacao = async (data: CheckoutDTO, token: string) => {
        try {
            data.token = token;
            // var response = await axiosInstance.post<FiliacaoResponseDTO>(`/filiacao`, JSON.stringify(data));
            
            
            var response: any = {};
            // MOCKUP para testes da Gt7 20/05/2024
            response.data = {
                "success": true,
                "message": "Filiação realizada com sucesso.",
                "data": {
                    "idFiliado": 21509172,
                    "matricula": "MG046949831",
                    "idContratoMensalidade": 900761733,
                    "tid": "0520112659711",
                    "franquia": "CARTAO DE VALE DO ACO",
                    "customerId": "9c582f01-1a93-4258-b884-3acb000a94d3",
                    "cartaoId": "7a2399b9-c00b-426d-ad3d-94f67d3ec619",
                    "idVendedor": 7209
                }
            } as any;
            return response.data;
        } catch (error) {
            return undefined;
        }
    };

    return { sendFiliacao };
}